import { Component } from './component'
import { clsBuilder } from '../bem-helper'
import { openIcon, closeIcon } from '../icons'

const cls = 'menu'
const bem = clsBuilder(cls)

export enum MenuItems {
	datasets = 'datasets',
	vizz = 'vizz',
	help = 'help',
	about = 'about'
}

type MenuItem = keyof typeof MenuItems

export class Menu extends Component {
	private menuBtn: HTMLElement
	private closeBtn: HTMLElement
	private menuItems: HTMLElement
	private activeItem: MenuItem = MenuItems.datasets
	private closed: boolean = true

	constructor(parentEl: HTMLElement) {
		super(cls)
		this.el.innerHTML = this.render()
		this.menuBtn = this.el.querySelector(bem`.${'__open'}`)!
		this.closeBtn = this.el.querySelector(bem`.${'__close'}`)!
		this.menuItems = this.el.querySelector(bem`.${'__items'}`)!
		this.enableItem(this.activeItem)
		this.setActive(this.activeItem)
		parentEl.appendChild(this.el)
		this.addEventHandlers()
	}

	private item(label: string): string {
		const target = label.toLowerCase()
		return bem`<a
            data-target="${target}"
            class="${'__item'}"
            disabled
        >${label}</a>`
	}

	protected render(): string {
		return bem`
            <div class="${'__icon'} ${'__close'} hidden" data-tooltip="Close menu">
                ${closeIcon}
            </div>
            <div class="${'__icon'} ${'__open'}" data-tooltip="Open menu">
                ${openIcon}
            </div>
            <nav class="${'__items'} ${'__items--hidden'}">
                ${this.item(MenuItems.datasets)}
                ${this.item(MenuItems.vizz)}
                ${this.item(MenuItems.help)}
                <a
					data-target="about"
					href="https://vizzuhq.com"
					target="_blank"
					class="${'__item'}"
				>
					${MenuItems.about}
				</a>
            </nav>
        `
	}

	private addEventHandlers(): void {
		this.menuBtn.addEventListener('pointerup', () => {
			this.toggle()
		})
		this.closeBtn.addEventListener('pointerup', () => {
			this.toggle()
		})
		this.menuItems.addEventListener('pointerup', (e: Event) => {
			this.onClick(e)
		})
	}

	private onClick(e: Event): void {
		e.preventDefault()
		const el = e.target as HTMLAnchorElement
		if (el.tagName !== 'A') return

		const itemName = el.dataset.target! as MenuItem
		this.setActive(itemName)
		this.toggle()
		this.trigger(itemName)
	}

	private toggle(): void {
		this.menuItems.classList.toggle(bem`${'__items--hidden'}`)
		this.menuBtn.classList.toggle('hidden')
		this.closeBtn.classList.toggle('hidden')
		this.closed = !this.closed
		this.trigger(this.closed ? 'menuclose' : 'menuopen')
	}

	public close(): void {
		if (!this.closed) {
			this.toggle()
		}
	}

	private enableItem(item: MenuItem): void {
		const el = this.menuItems.querySelector(`[data-target="${item as string}"]`)
		el?.removeAttribute('disabled')
	}

	private disableItem(item: MenuItem): void {
		const el = this.menuItems.querySelector(`[data-target="${item as string}"]`)
		el?.setAttribute('disabled', '')
	}

	public navigateTo(item: MenuItem): void {
		this.enableItem(item)
		this.setActive(item)
		this.close()
	}

	private setActive(item: MenuItem): void {
		if (item === MenuItems.help || item === MenuItems.about) return

		if (item === MenuItems.vizz) {
			this.enableItem(MenuItems.help)
		} else if (item === MenuItems.datasets) {
			this.disableItem(MenuItems.help)
		}
		this.activeItem = item
		this.el.querySelectorAll(bem`.${'__item'}`).forEach((item) => {
			const isActive = (item as HTMLElement).dataset.target === this.activeItem
			item.classList.toggle(bem`${'__item--active'}`, isActive)
		})
	}
}
