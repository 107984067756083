import { Data } from 'vizzu'

export type SeriesType = 'dimension' | 'measure'

export class DataSeries {
	private items: string[] = []
	private seriesType: SeriesType
	private maxItems: number = 0

	constructor(seriesType: SeriesType, maxItems: number) {
		this.seriesType = seriesType
		this.maxItems = maxItems
	}

	public at(index: number): string {
		return this.items[index]
	}

	get length(): number {
		return this.items.length
	}

	public add(series: string): void {
		if (this.items.includes(series)) return

		if (this.length < this.maxItems) {
			this.items.push(series)
		} else {
			this.items[this.length - 1] = series
		}
	}

	public remove(series: string): void {
		if (this.seriesType === 'measure' && this.length === 1) return

		this.items = this.items.filter((item) => item !== series)
	}

	public switch(from: Data.Series, to: Data.Series): void {
		const fromIdx = this.items.findIndex((item) => item === from.name)
		const toIdx = this.items.findIndex((item) => item === to.name)
		this.items.splice(fromIdx, 1, to.name)
		if (toIdx !== -1) {
			this.items.splice(toIdx, 1, from.name)
		}
	}

	public includes(series: string): boolean {
		return this.items.includes(series)
	}

	public setAt(index: number, series: string): void {
		this.items[index] = series
	}

	public getItems(): string[] {
		return this.items
	}

	public clone(): DataSeries {
		const series = new DataSeries(this.seriesType, this.maxItems)
		series.items = [...this.items]
		return series
	}
}
