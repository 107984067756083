import { VizzuParser } from '@vizzu/csvloader'

import { FileLoader } from '../file-loader'
import { Component } from './component'
import { clsBuilder } from '../bem-helper'

const cls = 'data-screen'
const bem = clsBuilder(cls)
const parser = new VizzuParser()
const maxSizeInMB = 5

export class DataScreen extends Component {
	public data: object = {}
	private _isHideable: boolean = false
	private cancelBtn: HTMLElement
	private errMsg: HTMLElement
	private fileInput: HTMLInputElement
	private vizzBtn: HTMLElement

	constructor(parentEl: HTMLElement) {
		super(cls)
		this.el.innerHTML = this.render()
		parentEl.appendChild(this.el)
		this.cancelBtn = document.querySelector(bem`.${'__cancel'}`)!
		this.fileInput = this.el.querySelector<HTMLInputElement>(bem`.${'__input'}`)!
		this.errMsg = this.el.querySelector(bem`.${'__error'}`)!
		this.vizzBtn = this.el.querySelector(bem`.${'__vizz'}`)!
		this.addEventHandlers()
	}

	protected render(): string {
		return bem`
            <header class="${'__header'}">
                <h1 class="data-screen__header-title">
                    <span class="${'__title-text'}">Scan your datasets with</span>
                    <img class="${'__logo'}" src="./static/images/logo.svg">
                </h1>
            </header>
            <div class="${'__form'}">
                <label class="${'__title'}" for="file">Select a CSV file:</label>
                <div class="${'__upload'}">
                    <input type="file" class="${'__input'}" id="file" name="file" accept=".csv">
                    <p class="${'__error'}"></p>
                </div>
            </div>
            <div class="${'__samples'}">
                <p class="${'__title'}">Or a sample dataset:</p>
                <ul class="${'__sample-files'}">
                    <li class="${'__sample-file-item'}" data-source="worldCupData">World Cup 2022 dataset</li>
                    <li class="${'__sample-file-item'}" data-source="salesData">Sample sales data</li>
                </ul>
            </div>
            <footer class="${'__footer'}">
                <button class="vizzu-button vizzu-button--active ${'__vizz'}" disabled>Vizz!</button>
                <button class="vizzu-button ${'__cancel'}" disabled>Cancel</button>
            </footer>
        `
	}

	get isHideable(): boolean {
		return this._isHideable
	}

	set isHideable(state: boolean) {
		this._isHideable = state
		if (state) {
			this.cancelBtn.removeAttribute('disabled')
		}
	}

	private addEventHandlers(): void {
		this.cancelBtn.addEventListener('pointerup', () => {
			this.hide(true)
		})

		this.fileInput.addEventListener('change', () => {
			this.onChange()
		})
		this.vizzBtn.addEventListener('pointerup', () => {
			this.trigger('load')
		})
		this.el.querySelectorAll(bem`.${'__sample-file-item'}`).forEach((item) => {
			item.addEventListener('pointerup', (e: Event) => {
				this.trigger('loadsample', { source: (e.target as HTMLElement).dataset.source })
			})
		})
	}

	private onChange(): void {
		if (this.fileInput.files === null || this.fileInput.files.length === 0) {
			return
		}
		const file = this.fileInput.files[0]

		if (file.size > maxSizeInMB * 1024 ** 2) {
			this.errMsg.innerHTML = `Please choose a file smaller than ${maxSizeInMB} MB`
			return
		}

		FileLoader.load(file)
			.then((content) => {
				const data = parser.parse(content)
				if (data !== null) {
					this.data = data
					this.vizzBtn.removeAttribute('disabled')
				}
				this.errMsg.innerHTML = ''
			})
			.catch((err) => {
				this.errMsg.innerHTML = err
			})
	}

	public hide(trigger: boolean = false): void {
		this.el.classList.add('hidden')
		if (trigger) {
			this.trigger('hide')
		}
	}

	public show(): void {
		this.el.classList.remove('hidden')
	}
}
