import Vizzu, { Anim, Event, Config, Styles, Data } from 'vizzu'
import { VizzuEvent } from './vizzu-patch'
import { SwipeTracker, Options, Events } from './swipe-tracker'
import { Position, SwipeDirection } from './position'
import { ChartState } from './chart-state'
import { SeriesType } from './data-series'
import { ChannelState } from './components/data-series-panel'

export enum Axis {
	X,
	Y,
	LEGEND
}

export class VizzuSwipe {
	private _chart: Vizzu
	private _swipeTracker: SwipeTracker
	private _control: Anim.Control | null = null
	private _state: ChartState
	private _nextState: ChartState
	private _animation: Anim.Completing
	private _savedState: ChartState | null = null
	private _el: HTMLElement
	private _defaultStyle: Styles.Chart = {
		plot: {
			xAxis: {
				label: {
					angle: '45deg'
				},
				title: {
					vposition: 'begin',
					vside: 'positive',
					paddingTop: '10px'
				}
			},
			yAxis: {
				title: {
					side: 'positive'
				}
			}
		},
		logo: {
			width: '0px'
		}
	}

	constructor(chart: Vizzu, containerId: string) {
		this._chart = chart
		this._el = document.getElementById(containerId)!
		this._nextState = this._state = ChartState.fromMetainfo(chart.data)
		this._animation = this._animate(this._state, false)
		this._swipeTracker = new SwipeTracker(
			this._getSwipeTrackerOptions(),
			this._getSwipeTrackerEvents()
		)
		this._setupChart()
		this._redirectTouchEvents()

		window.addEventListener('resize', () => {
			this._swipeTracker.setup(this._getSwipeTrackerOptions())
		})
	}

	public element(): HTMLElement {
		return this._el
	}

	private _getSwipeTrackerOptions(): Options {
		// FIXME: clientWidth/Height is 0 on first call
		return {
			startThreshold: 30,
			acceptTreshold: 0.6,
			xInterval: this.element().clientWidth / 1.5,
			yInterval: this.element().clientHeight / 1.5
		}
	}

	private _animate(state: ChartState, grabCtrl: boolean = true, data?: object): Anim.Completing {
		const target: Anim.Target = {
			style: this._defaultStyle,
			config: state.getConfig(),
			data
		}
		const options: Anim.Options = {
			duration: '600ms'
		}
		const animation = this._chart.animate(target, options)
		animation.activated.then((ctrl) => {
			if (grabCtrl) {
				this._control = ctrl
				this._control.pause()
			}
		})
		return animation
	}

	private _getSwipeTrackerEvents(): Events {
		const events: Events = {
			started: (element, direction): void => {
				this._nextState = this._state.clone()
				this._swipeUpdate(element, direction)
				this._animation = this._animate(this._nextState)
			},

			changed: (progress: number): void => {
				if (!this._control) return
				this._control.seek(`${100 * progress}%`)
			},

			finished: (): boolean => {
				if (!this._control) return true
				this._control.play()
				this._animation.then((chart) => {
					this._state = this._nextState
					this._swipeTracker.reset()
					this.element().dispatchEvent(
						new CustomEvent('stateupdate', { detail: { state: this._state } })
					)
					return chart
				})
				return false
			},

			canceled: (): boolean => {
				if (!this._control) return true
				this._control.reverse()
				this._control.play()
				this._animation.then((chart) => {
					this._swipeTracker.reset()
					return chart
				})
				return false
			}
		}

		return events
	}

	private _afterUpdate(sendUpdate: boolean = true, data?: object): void {
		this._animation = this._animate(this._nextState, undefined, data)
		let e: CustomEvent | null = null
		if (sendUpdate) {
			e = new CustomEvent('stateupdate', {
				detail: {
					state: this._nextState
				}
			})
			if (typeof data === 'undefined') {
				this.element().dispatchEvent(e as Event)
			}
		}
		this._animation.activated.then((ctrl) => {
			ctrl.play()
		})
		this._animation.then((chart) => {
			if (sendUpdate && typeof data !== 'undefined') {
				this.element().dispatchEvent(e as Event)
			}
			this._state = this._nextState
			return chart
		})
	}

	public switchChannels(from: Data.Series, to: Data.Series): void {
		if (from === to) return

		this._nextState = this._state.clone()
		this._nextState.switchSelection(from, to)
		this._afterUpdate(false)
	}

	public move(data: Data.TableBySeries): void {
		this._afterUpdate(true, data)
	}

	public getLastState(): Config.Chart {
		return this._nextState.getConfig()
	}

	public updateState({
		series,
		type,
		state
	}: {
		series: string
		type: SeriesType
		state: ChannelState
	}): void {
		this._nextState = this._state.clone()
		if (state === ChannelState.ON) {
			this._nextState.add(series, type)
		} else {
			this._nextState.remove(series, type)
		}
		this._afterUpdate()
	}

	private _swipeUpdate(element: string, swipe: Position): void {
		if (!swipe.horizontal()) {
			if (element.startsWith('legend')) {
				this._setNext(Axis.LEGEND, swipe.getVerticalDirection())
			} else {
				this._setNext(Axis.Y, swipe.getVerticalDirection())
			}
		} else {
			this._setNext(Axis.X, swipe.getHorizontalDirection())
		}
	}

	private _setNext(axis: Axis, direction: SwipeDirection): void {
		const series = this._getNextUnselectedSeriesByAxis(axis, direction)
		if (series !== null) {
			this._nextState.setAxis(axis, series)
		}
	}

	private _getNextUnselectedSeriesByAxis(
		axis: Axis,
		direction: SwipeDirection
	): Data.SeriesName | null {
		const config = this._state.getConfig()
		const series = this._chart.data.series
		const dimensions = series.filter((s) => s.type === 'dimension').map((s) => s.name)
		const measures = series.filter((s) => s.type === 'measure').map((s) => s.name)

		// if no selection return first
		// (NB axis cannot be legend here as it is not shown if no selection)
		if (axis === Axis.X && (config.x! as Data.SeriesList).length === 0) {
			return dimensions[0]
		} else if (axis === Axis.Y && (config.y! as Data.SeriesList).length === 0) {
			return measures[0]
		}

		// return next unselected
		const d = direction === SwipeDirection.Up || direction === SwipeDirection.Left ? -1 : 1
		let selected = ''
		if (axis === Axis.X) {
			selected = (config.x! as Data.SeriesList)[0]
		} else if (axis === Axis.Y) {
			selected = (config.y! as Data.SeriesList)[0]
		} else if (axis === Axis.LEGEND) {
			selected = (config.color! as Data.SeriesList)[0]
		}
		let next = null
		if (measures.includes(selected)) {
			next = this._getNextUnselected(measures, selected, d, this._state.measures)
		} else {
			next = this._getNextUnselected(dimensions, selected, d, this._state.dimensions)
		}
		return next
	}

	private _getNextUnselected(
		items: string[],
		selected: string,
		dir: number,
		selection: string[]
	): string | null {
		let next = null
		const selectedIdx = items.indexOf(selected)
		for (let i = 1; i < items.length; i++) {
			const idx = (selectedIdx + i * dir + items.length) % items.length
			if (!selection.includes(items[idx])) {
				next = items[idx]
				break
			}
		}
		return next
	}

	private _setupChart(): void {
		this._chart.on('click', (event) => {
			event.preventDefault()
		})

		this._chart.on('pointerdown', (event: Event.Object) => {
			this._swipeTracker.mousedown(
				Object.assign(new Position(0, 0), (event as VizzuEvent).data.position),
				(event as VizzuEvent).data.element
			)
			event.preventDefault()
		})

		this._chart.on('pointermove', (event) => {
			this._swipeTracker.mousemove(
				Object.assign(new Position(0, 0), (event as VizzuEvent).data.position)
			)
			event.preventDefault()
		})

		this._chart.on('pointerup', (event) => {
			this._swipeTracker.mouseup()
			event.preventDefault()
		})
	}

	private _redirectTouchEvents(): void {
		const container = this.element()
		const fn = (e: TouchEvent): void => {
			this._touch2MouseEvent(e)
		}
		container.addEventListener('touchstart', fn)
		container.addEventListener('touchmove', fn)
		container.addEventListener('touchend', fn)
		container.addEventListener('touchcancel', fn)
	}

	private _touch2MouseType(type: string): string {
		const typeMap: Record<string, string> = {
			touchstart: 'mousedown',
			touchmove: 'mousemove',
			touchend: 'mouseup',
			touchcancel: 'mouseup'
		}
		return typeMap[type] ?? ''
	}

	private _touch2MouseEvent(touchEvent: TouchEvent): void {
		touchEvent.stopPropagation()
		touchEvent.preventDefault()

		const mouseEvent = new MouseEvent(this._touch2MouseType(touchEvent.type), {
			bubbles: true,
			cancelable: true,
			clientX: touchEvent.touches[0]?.clientX,
			clientY: touchEvent.touches[0]?.clientY
		})

		if (touchEvent.target) {
			touchEvent.target.dispatchEvent(mouseEvent)
		}
	}

	public show(): void {
		this.element().classList.remove('hidden')
		this._swipeTracker.setup(this._getSwipeTrackerOptions())
	}

	public save(): void {
		this._savedState = this._state.clone()
	}

	public reset(): void {
		this._savedState = null
	}

	public rewind(): void {
		if (this._savedState === null) return

		this._nextState = this._savedState
		this._afterUpdate()
		this.reset()
	}
}
