import { Component } from './component'

const cls = 'tooltip'

export class Tooltip extends Component {
	private anchor: HTMLElement | null = null
	private transition: number | null = null

	constructor() {
		super(cls)
		this.el.classList.add(`${cls}--hidden`)
		this.el.innerHTML = this.render()
		document.body.appendChild(this.el)
	}

	render(): string {
		return ''
	}

	public attach(anchor: HTMLElement): void {
		if (this.transition !== null) {
			window.clearTimeout(this.transition)
			this.el.classList.remove(`${cls}--hiding`)
		}
		this.anchor = anchor
		this.setContent()
		this.el.classList.remove('hidden')
		this.setPosition()
		this.el.classList.remove(`${cls}--hidden`)
	}

	public detach(): void {
		this.anchor = null
		this.el.classList.add(`${cls}--hiding`)
		this.transition = window.setTimeout(() => {
			this.setContent()
			this.el.classList.add('hidden')
			this.el.classList.remove(`${cls}--hiding`)
			this.el.classList.add(`${cls}--hidden`)
		}, this.getCSSPropertyValue('--transition-duration'))
	}

	private setContent(): void {
		this.el.innerHTML = this.anchor?.dataset.tooltip ?? ''
	}

	private setPosition(): void {
		const anchorRect = this.anchor!.getBoundingClientRect()
		const rect = this.el.getBoundingClientRect()
		this.el.style.top = `${anchorRect.y}px`
		const left = anchorRect.x - rect.width - 10
		if (left > 0) {
			this.el.style.left = `${left}px`
		} else {
			this.el.style.left = `${anchorRect.x + anchorRect.width + 10}px`
		}
	}
}
