import { Config } from 'vizzu'
import { ChannelType } from './components/data-series-item'

export class ConfigHelper {
	constructor(private config: Config.Chart) {}

	public getChannelFromName(name: string): ChannelType | null {
		const x = this.config.x! as string[]
		const y = this.config.y! as string[]
		const noop = this.config.noop! as string[]
		if (y.length === 0) {
			y.push('count()')
		}
		const color = this.config.color! as string[]
		const size = this.config.size! as string[]
		const lightness = this.config.lightness! as string[]

		let channel: ChannelType | null = null
		if (noop.includes(name)) {
			channel = ChannelType.noop
		} else if (color.length > 0 && color[0] === name) {
			channel = ChannelType.color
		} else if (size.length > 0 && size[0] === name) {
			channel = ChannelType.size
		} else if (x.includes(name)) {
			channel = ChannelType.x
		} else if (y.includes(name)) {
			channel = ChannelType.y
		} else if (lightness.length > 0 && lightness[0] === name) {
			channel = ChannelType.lightness
		}
		return channel
	}

	public isSelected(name: string): boolean {
		const selected = Object.values(this.config).flat()
		return selected.includes(name)
	}
}
