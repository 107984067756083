export enum SwipeDirection {
	Up,
	Down,
	Left,
	Right
}

export class Position {
	constructor(
		private x: number,
		private y: number
	) {}

	abs(): Position {
		return new Position(Math.abs(this.x), Math.abs(this.y))
	}

	sub(pos: Position): Position {
		return new Position(this.x - pos.x, this.y - pos.y)
	}

	chebyshevDistance(): number {
		const tmp = this.abs()
		return Math.max(tmp.x, tmp.y)
	}

	horizontal(): boolean {
		const tmp = this.abs()
		return tmp.x > tmp.y
	}

	direction(): Position {
		return this.horizontal()
			? new Position(Math.sign(this.x), 0)
			: new Position(0, Math.sign(this.y))
	}

	eq(pos: Position): boolean {
		return this.x === pos.x && this.y === pos.y
	}

	left(): boolean {
		return this.x < 0
	}

	down(): boolean {
		return this.y > 0
	}

	up(): boolean {
		return this.y < 0
	}

	right(): boolean {
		return this.x > 0
	}

	getHorizontalDirection(): SwipeDirection {
		return this.x > 0 ? SwipeDirection.Right : SwipeDirection.Left
	}

	getVerticalDirection(): SwipeDirection {
		return this.y > 0 ? SwipeDirection.Down : SwipeDirection.Up
	}
}
