import { Component } from './component'

export interface HelpOverlayItemAlignment {
	vertical: string
	horizontal: string
}

const cls = 'help-overlay__item'

export class HelpOverlayItem extends Component {
	private anchor: HTMLElement
	private alignment: HelpOverlayItemAlignment
	private border: boolean

	constructor(
		anchor: HTMLElement,
		content: string,
		alignment: HelpOverlayItemAlignment,
		border: boolean = true
	) {
		super(cls)
		this.el.innerHTML = this.render(content)
		this.anchor = anchor
		this.alignment = alignment
		this.border = border
		document.querySelector('.help-overlay')!.appendChild(this.el)
		this.el.classList.add('hidden')
	}

	public show(): Promise<Animation> {
		if (this.border) {
			this.anchor.classList.add('help-overlay__parent')
		}
		this.el.classList.remove('hidden')
		this.setPosition()
		const animate = this.animate()
		return animate.finished
	}

	private animate(direction: 'normal' | 'reverse' = 'normal'): Animation {
		const keyframes: Keyframe[] = [
			{
				opacity: 0,
				transform: 'translateX(-10%)'
			},
			{
				opacity: 1,
				transform: 'translateX(0)'
			}
		]
		const options: KeyframeAnimationOptions = {
			duration: this.getCSSPropertyValue('--transition-duration'),
			easing: 'ease-out',
			direction
		}
		return this.el.animate(keyframes, options)
	}

	public setPosition(): void {
		const anchorRect = this.anchor.getBoundingClientRect()
		const overlayRect = this.el.getBoundingClientRect()

		let top = ''
		if (this.alignment.vertical === 'center') {
			top = `${anchorRect.top + anchorRect.height / 2 - overlayRect.height / 2}px`
		} else {
			top = this.alignment.vertical
		}

		let left = ''
		if (this.alignment.horizontal === 'left') {
			left = `${anchorRect.left - overlayRect.width - 5}px`
		} else {
			const [leftM, leftD] = this.alignment.horizontal.split('/')
			left = window.innerWidth < 768 ? leftM : leftD
		}

		this.el.style.top = top
		this.el.style.left = left
	}

	protected render(content: string): string {
		return `${content}<div class="help-overlay__triangle"></div>`
	}

	public hide(): void {
		this.anchor.classList.remove('help-overlay__parent')
		this.animate('reverse')
		window.setTimeout(() => {
			this.el.classList.add('hidden')
		}, this.getCSSPropertyValue('--transition-duration'))
	}
}
