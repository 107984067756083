type Vector = {
	x: number
	y: number
}

export abstract class Component {
	public el: HTMLElement

	constructor(cls: string, tagName: string = 'div') {
		this.el = document.createElement(tagName)
		this.el.classList.add(cls)
	}

	public on(
		event: string,
		callback: EventListenerOrEventListenerObject,
		options?: AddEventListenerOptions
	): void {
		this.el.addEventListener(event, callback, options)
	}

	protected trigger(name: string, detail?: object): void {
		this.el.dispatchEvent(new CustomEvent(name, { detail }))
	}

	protected removeModifier(cls: string, ...modifiers: string[]): void {
		this.setModifier(cls, false, ...modifiers)
	}

	protected addModifier(cls: string, ...modifiers: string[]): void {
		this.setModifier(cls, true, ...modifiers)
	}

	protected setModifier(cls: string, on: boolean, ...modifiers: string[]): void {
		modifiers
			.map((name) => `${cls}--${name}`)
			.forEach((name) => {
				this.el.querySelector(`.${cls}`)?.classList.toggle(name, on)
			})
	}

	protected isPositionOverElement(v: Vector, el: HTMLElement): boolean {
		const rect = el.getBoundingClientRect()
		return (
			v.x > rect.x && v.x < rect.x + rect.width && v.y > rect.y && v.y < rect.y + rect.height
		)
	}

	protected abstract render(...args: string[]): string

	protected getCSSPropertyValue(property: string, el?: HTMLElement): number {
		el ??= this.el
		return parseInt(getComputedStyle(el).getPropertyValue(property), 10)
	}
}
