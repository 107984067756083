import { Data, Config } from 'vizzu'
import { Axis } from './vizzu-swipe'
import { DataSeries, SeriesType } from './data-series'

const maxDimensions = 2
const maxMeasures = 4

export class ChartState {
	private x: Data.SeriesName[] = []
	private y: Data.SeriesName[] = []
	private size: Data.SeriesName[] = []
	private color: Data.SeriesName[] = []
	private noop: Data.SeriesName[] = []
	private lightness: Data.SeriesName[] = []
	private circle: boolean = false
	private _measures: DataSeries = new DataSeries('measure', maxMeasures)
	private _dimensions: DataSeries = new DataSeries('dimension', maxDimensions)

	public static fromMetainfo(metainfo: Data.Metainfo): ChartState {
		const state = new ChartState()
		const defaultDimension = metainfo.series.filter((s) => s.type === 'dimension')[0].name
		const defaultMeasure = metainfo.series.filter((s) => s.type === 'measure')[0].name
		state.x.push(defaultDimension)
		state.y.push(defaultMeasure)
		state._dimensions.add(defaultDimension)
		state._measures.add(defaultMeasure)
		return state
	}

	public clone(): ChartState {
		const state = new ChartState()
		;(['x', 'y', 'size', 'color', 'lightness', 'noop'] as const).forEach((prop) => {
			state[prop] = [...this[prop]]
		})
		state.circle = this.circle
		state._dimensions = this._dimensions.clone()
		state._measures = this._measures.clone()
		return state
	}

	public getConfig(): Config.Chart {
		const config: Config.Chart = {
			x: this.x,
			y: this.y,
			size: this.size,
			color: this.color,
			lightness: this.lightness,
			noop: this.noop,
			geometry: this.circle ? 'circle' : 'rectangle'
		}
		return config
	}

	public add(series: Data.SeriesName, to: SeriesType): void {
		if (to === 'measure') {
			this._measures.add(series)
		} else {
			this._dimensions.add(series)
		}
		this.update()
	}

	public remove(series: Data.SeriesName, from: SeriesType): void {
		if (from === 'measure') {
			this._measures.remove(series)
		} else {
			this._dimensions.remove(series)
		}
		this.update()
	}

	public update(): void {
		this.y = [this._measures.at(0)]
		const m = this._measures.length
		const d = this._dimensions.length
		if (m === 1 && d === 2) {
			this.y.push(this._dimensions.at(1))
		}
		if (m === 1) {
			if (d === 0) {
				this.x = []
			} else {
				this.x = [this._dimensions.at(0)]
			}
		} else {
			this.x = [this._measures.at(1)]
		}
		if (m > 1 && d > 0) {
			this.color = [this._dimensions.at(0)]
		} else if (m === 1 && d === 2) {
			this.color = [this._dimensions.at(1)]
		} else {
			this.color = []
		}
		if (m > 2) {
			this.size = [this._measures.at(2)]
		} else {
			this.size = []
		}
		if (m > 3) {
			this.lightness = [this._measures.at(3)]
		} else {
			this.lightness = []
		}
		if (m > 1 && d === 2) {
			this.noop = [this._dimensions.at(1)]
		} else {
			this.noop = []
		}
		this.circle = m >= 2
	}

	public switchSelection(from: Data.Series, to: Data.Series): void {
		if (from.type === 'measure') {
			this._measures.switch(from, to)
		} else {
			this._dimensions.switch(from, to)
		}
		this.update()
	}

	public setAxis(axis: Axis, to: Data.SeriesName): void {
		// TODO: remove duplicated logic
		if (axis === Axis.X) {
			if (this._dimensions.includes(this.x[0])) {
				this._dimensions.setAt(0, to)
			} else {
				this._measures.setAt(1, to)
			}
		} else if (axis === Axis.Y) {
			this._measures.setAt(0, to)
		} else if (axis === Axis.LEGEND) {
			const m = this._measures.length
			const d = this._dimensions.length
			if (m > 1 && d > 0) {
				this._dimensions.setAt(0, to)
			} else if (m === 1 && d === 2) {
				this._dimensions.setAt(1, to)
			}
		} else {
			return
		}
		this.update()
	}

	get dimensions(): string[] {
		return this._dimensions.getItems()
	}

	get measures(): string[] {
		return this._measures.getItems()
	}
}
