export function clsBuilder(cls: string) {
	return (strings: TemplateStringsArray, ...values: string[]): string =>
		values
			.map((value, i) => `${strings[i]}${getFullClassname(value, cls)}`)
			.concat([strings[strings.length - 1]])
			.join('')
}

function getFullClassname(value: string, cls: string): string {
	if (value.startsWith('__') || value.startsWith('--')) {
		return `${cls}${value}`
	} else {
		return value
	}
}
