import { ChannelType } from './components/data-series-item'
import { sizeIcon, colorIcon, lightnessIcon } from './icons'

export enum DragImageState {
	active,
	inactive
}

const cls: string = 'drag-image'
const panelWidth: number = parseInt(
	getComputedStyle(document.body).getPropertyValue('--panel-width'),
	10
)

export class DragImage {
	public el: HTMLImageElement
	private size: number
	private yPos: number

	constructor(parentEl: HTMLElement) {
		this.el = new Image()
		this.el.classList.add(cls)
		parentEl.appendChild(this.el)
		const imgSizeVar = getComputedStyle(this.el as Element).getPropertyValue('--image-size')
		this.size = parseInt(imgSizeVar, 10)
		this.yPos = parseInt(getComputedStyle(this.el).getPropertyValue('--drag-y-start'), 10)
	}

	public setFromChannel(channel: ChannelType): void {
		if (channel === ChannelType.size) {
			this.el.src = `data:image/svg+xml;base64,${btoa(sizeIcon)}`
		} else if (channel === ChannelType.color) {
			this.el.src = `data:image/svg+xml;base64,${btoa(colorIcon)}`
		} else if (channel === ChannelType.lightness) {
			this.el.src = `data:image/svg+xml;base64,${btoa(lightnessIcon)}`
		} else {
			this.el.src = this.drawIcon(channel)
		}
		this.animate(DragImageState.active)
		if (channel === ChannelType.size) {
			this.el.classList.add(`${cls}--size`)
		}
	}

	private drawIcon(channel: ChannelType): string {
		// TODO: simplify - maybe can we clone the element instead of drawing an image?
		const drawText = (text: string): void => {
			context.font = '1000 16px "Roboto Flex"'
			const metrics = context.measureText(text)
			const height = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
			context.fillText(text, this.size / 2 - metrics.width / 2, this.size / 2 + height / 2)
		}

		const drawNoop = (): void => {
			context.font = '1000 12px "Roboto Flex"'
			let metrics = context.measureText('NO')
			context.fillText('NO', this.size / 2 - metrics.width / 2, this.size / 2)
			metrics = context.measureText('OP')
			const height = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent
			context.fillText('OP', this.size / 2 - metrics.width / 2, this.size / 2 + height)
		}

		const canvas = document.createElement('canvas')
		canvas.style.height = `${this.size}px`
		canvas.style.width = `${this.size}px`
		canvas.height = this.size * 2
		canvas.width = this.size * 2
		const context = canvas.getContext('2d')!
		context.scale(2, 2)
		context.fillStyle = getComputedStyle(document.body).getPropertyValue('--accent-color')
		context.beginPath()
		context.arc(this.size / 2, this.size / 2, this.size / 2, 0, Math.PI * 2)
		context.fill()
		context.fillStyle = getComputedStyle(document.body).getPropertyValue('--bg-color')
		if (channel === ChannelType.x) {
			drawText('X')
		} else if (channel === ChannelType.y) {
			drawText('Y')
		} else {
			drawNoop()
		}
		return canvas.toDataURL()
	}

	public updatePosition(el: HTMLElement): void {
		const rect = el.getBoundingClientRect()
		this.el.style.top = `${rect.y}px`
	}

	public animate(state: DragImageState): void {
		const keyframes: Keyframe[] = [
			{
				offset: 0,
				opacity: 0,
				transform: 'translateX(0)',
				zIndex: 'var(--hidden-layer)'
			},
			{
				offset: 0.1,
				opacity: 1,
				zIndex: 'var(--panel-drag-layer)'
			},
			{
				offset: 1,
				opacity: 1,
				transform: `translateX(-${panelWidth - this.yPos - this.size / 2}px)`,
				zIndex: 'var(--panel-drag-layer)'
			}
		]
		const timing: KeyframeAnimationOptions = {
			direction: 'normal',
			duration: 200,
			iterations: 1,
			fill: 'forwards'
		}
		if (state === DragImageState.inactive) {
			timing.direction = 'reverse'
		}
		const animation = this.el.animate(keyframes, timing)
		if (state === DragImageState.inactive) {
			animation.finished.then(() => {
				this.el.src = ''
			})
		}
	}
}
