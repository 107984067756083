import Vizzu, { Data } from 'vizzu'
import { VizzuSwipe } from './vizzu-swipe'
import { DataScreen } from './components/data-screen'
import { Menu, MenuItems } from './components/menu'
import { DataSeriesPanel } from './components/data-series-panel'
import { HelpOverlayItem } from './components/help-overlay-item'
import { FullscreenButton } from './fullscreen'
import { Tooltip } from './components/tooltip'

import worldCupData from './data/world-cup-2022.json'
import salesData from './data/sales-sample.json'

// @ts-ignore
import VizzuModule from 'vizzu/dist/cvizzu.wasm'

import './scss/main.scss'

Vizzu.options({ wasmUrl: VizzuModule })

type ChartData = {
	records: Data.Record[]
	series: Data.Series[]
}

let chart: Vizzu
let vizzuSwipe: VizzuSwipe
let chartData: ChartData
const sampleData: { [index: string]: object } = {
	worldCupData,
	salesData
}
const overlayItems: HelpOverlayItem[] = []
const main = document.querySelector('main')!
const tooltip = new Tooltip()

const menu = new Menu(main)
menu.on('datasets', () => {
	showDataScreen()
})
menu.on('vizz', () => {
	showChart()
})
menu.on('help', () => {
	showHelp()
})

const dataScreen = new DataScreen(main)
dataScreen.on('load', () => {
	menu.navigateTo(MenuItems.vizz)
	initChart(dataScreen.data)
})
dataScreen.on('hide', () => {
	vizzuSwipe.show()
	dataSeriesPanel.unhide()
	menu.navigateTo(MenuItems.vizz)
})
dataScreen.on('loadsample', (e) => {
	const source = (e as CustomEvent).detail.source
	initChart(sampleData[source])
	menu.navigateTo(MenuItems.vizz)
})

const dataSeriesPanel = new DataSeriesPanel(main)
dataSeriesPanel.on('channelupdate', (e) => {
	vizzuSwipe.updateState((e as CustomEvent).detail)
})
dataSeriesPanel.on('channelswitch', (e) => {
	const detail = (e as CustomEvent).detail
	vizzuSwipe.switchChannels(detail.from, detail.to)
})
dataSeriesPanel.on('panelopen', () => {
	menu.close()
})
dataSeriesPanel.on('channeldragstart', () => {
	vizzuSwipe.save()
})
dataSeriesPanel.on('channeldrop', () => {
	vizzuSwipe.reset()
	dataSeriesPanel.setContent(chart.data.series, vizzuSwipe.getLastState())
})
dataSeriesPanel.on('seriesdragcancel', () => {
	vizzuSwipe.rewind()
})
dataSeriesPanel.on('seriesmove', (e) => {
	moveSeries(e)
})
menu.on('menuopen', () => {
	dataSeriesPanel.close()
})

const btnEl = document.querySelector('.fullscreen-icon') as HTMLButtonElement
const fullscreenButton = new FullscreenButton(main, btnEl)

function moveSeries(e: Event): void {
	const series = (e as CustomEvent).detail.series
	const idx = chartData.series.findIndex((s) => s.name === series.name)
	const values = chartData.records.map((r) => r[idx])
	const data: Data.TableBySeries = {
		series: [
			{
				name: series.name,
				type: series.type === 'dimension' ? 'measure' : 'dimension',
				values: values as Data.Values
			}
		]
	}
	vizzuSwipe.move(data)
}

function showDataScreen(): void {
	dataScreen.show()
	document.querySelector('#vizzu-swipe')!.classList.add('hidden')
	dataSeriesPanel.hide()
	fullscreenButton.hide()
}

function showChart(): void {
	if (typeof chart === 'undefined') return

	dataScreen.hide()
	dataScreen.isHideable = true
	vizzuSwipe.show()
	dataSeriesPanel.unhide()
	dataSeriesPanel.setContent(chart.data.series, vizzuSwipe.getLastState())
	fullscreenButton.show()
	if (localStorage.getItem('overlaySeen') === null) {
		showHelp()
	}
}

function initChart(data: object): void {
	chartData = data as ChartData
	if (typeof chart !== 'undefined') {
		chart.detach()
		// TODO: remove after upgrade
		const canvas = document.querySelector('#vizzu-swipe > canvas')!
		canvas.remove()
	}

	const containerId = 'vizzu-swipe'
	chart = new Vizzu(containerId, { data })
	chart.initializing.then((chart) => {
		vizzuSwipe = new VizzuSwipe(chart, containerId)
		showChart()
		vizzuSwipe.element().addEventListener('stateupdate', (e: Event) => {
			const config = (e as CustomEvent).detail.state.getConfig()
			dataSeriesPanel.setContent(chart.data.series, config)
		})
	})
}

function showHelp(): void {
	localStorage.setItem('overlaySeen', 'true')
	if (overlayItems.length === 0) {
		prepareOverlays()
	}
	dataSeriesPanel.on(
		'panelopen',
		() => {
			Promise.all(overlayItems.map((item) => item.show())).then(() => {
				document.body.addEventListener('pointerup', hideHelp, { once: true })
			})
		},
		{ once: true }
	)
	dataSeriesPanel.open()
}

function hideHelp(): void {
	overlayItems.forEach((item) => item.hide())
}

function prepareTooltips(): void {
	const tooltips = document.querySelectorAll('[data-tooltip]')
	tooltips.forEach((t) => {
		t.addEventListener('pointerenter', () => {
			tooltip.attach(t as HTMLElement)
		})
		t.addEventListener('pointerleave', () => {
			tooltip.detach()
		})
	})
}

function prepareOverlays(): void {
	overlayItems.push(
		new HelpOverlayItem(
			document.querySelector('.data-series__channel--x')!,
			'Drag and drop this icon to change channel',
			{ vertical: 'center', horizontal: 'left' }
		)
	)
	const idx = Math.min(5, chartData.series.length - 1)
	overlayItems.push(
		new HelpOverlayItem(
			document.querySelectorAll('.data-series__name')[idx]! as HTMLElement,
			'Click or tap on an item to select/deselect, drag onto the other category to change type',
			{ vertical: 'center', horizontal: 'left' }
		)
	)
	overlayItems.push(
		new HelpOverlayItem(
			document.querySelector('#vizzu-swipe')!,
			'Swipe across the chart horizontally or vertically to change data series',
			{ vertical: '30%', horizontal: '10%/15%' },
			false
		)
	)
}

window.addEventListener('load', () => {
	document.querySelector('.load-overlay')!.classList.add('hidden')
	dataScreen.show()
	prepareTooltips()
})

window.addEventListener('resize', () => {
	if (overlayItems.length === 0) return

	overlayItems.forEach((item) => item.setPosition())
})
