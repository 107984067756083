export const sizeIcon = `<svg viewBox="0 0 46 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_131_96)">
        <path d="M42 24.5C42 34.165 34.165 42 24.5 42C14.835 42 7 34.165 7 24.5C7 14.835 14.835 7 24.5 7C34.165 7 42 14.835 42 24.5Z" fill="#FDFDFD"/>
    </g>
    <ellipse cx="25" cy="24.5" rx="13" ry="12.5" fill="#918CCE"/>
    <circle cx="28" cy="27" r="9" fill="white"/>
    <circle cx="31" cy="30" r="5" fill="#918CCE"/>
    <defs>
        <filter id="filter0_d_131_96" x="0" y="0" width="49" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="3.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.0458333 0 0 0 0 0.0450694 0 0 0 0 0.0450694 0 0 0 0.05 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_131_96"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_131_96" result="shape"/>
        </filter>
    </defs>
</svg>
`

export const colorIcon = `<svg viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17" cy="17" r="16" fill="white" stroke="#918CCE" stroke-width="2"/>
    <circle cx="13" cy="19" r="8" fill="#9079FF" fill-opacity="0.6"/>
    <circle cx="17" cy="12" r="8" fill="#00EF61" fill-opacity="0.6"/>
    <circle cx="21" cy="19" r="8" fill="#FF696B" fill-opacity="0.6"/>
</svg>
`

export const closeIcon = `<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="21.0254" y="3.09595" width="26.7153" height="3" transform="rotate(135 21.0254 3.09595)" fill="none"/>
    <rect x="18.8433" y="21.9649" width="26.6437" height="3" transform="rotate(-135 18.8433 21.9649)" fill="none"/>
</svg>`

export const openIcon = `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0" y="18" width="21" height="3" fill="none" />
    <rect x="0" y="9" width="21" height="3" fill="none" />
    <rect x="0" y="0" width="21" height="3" fill="none" />
</svg>`

export const fullscreenIconOff = `<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="7" y="25" width="7" height="3" fill="#918CCE"/>
    <rect x="7" y="28" width="7" height="3" transform="rotate(-90 7 28)" fill="#918CCE"/>
    <rect x="21" y="25" width="7" height="3" fill="#918CCE"/>
    <rect x="25" y="28" width="7" height="3" transform="rotate(-90 25 28)" fill="#918CCE"/>
    <rect x="25" y="14" width="7" height="3" transform="rotate(-90 25 14)" fill="#918CCE"/>
    <rect x="28" y="10" width="7" height="3" transform="rotate(180 28 10)" fill="#918CCE"/>
    <rect x="14" y="10" width="7" height="3" transform="rotate(180 14 10)" fill="#918CCE"/>
    <rect x="10" y="7" width="7" height="3" transform="rotate(90 10 7)" fill="#918CCE"/>
</svg>`

export const fullscreenIconOn = `<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="14" y="24" width="7" height="3" transform="rotate(-180 14 24)" fill="#918CCE"/>
    <rect x="14" y="21" width="7" height="3" transform="rotate(90 14 21)" fill="#918CCE"/>
    <rect x="28" y="24" width="7" height="3" transform="rotate(-180 28 24)" fill="#918CCE"/>
    <rect x="24" y="21" width="7" height="3" transform="rotate(90 24 21)" fill="#918CCE"/>
    <rect x="24" y="7" width="7" height="3" transform="rotate(90 24 7)" fill="#918CCE"/>
    <rect x="21" y="11" width="7" height="3" fill="#918CCE"/>
    <rect x="7" y="11" width="7" height="3" fill="#918CCE"/>
    <rect x="11" y="14" width="7" height="3" transform="rotate(-90 11 14)" fill="#918CCE"/>
</svg>`

export const lightnessIcon = `<svg width="35" height="35" fill="none" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 35 35">
    <circle cx="17.5" cy="17.5" r="16.5" fill="#FDFDFD" stroke="#918CCE" stroke-width="2"/>
    <circle cx="17.5" cy="17.5" r="13" fill="url(#paint0_linear_1096_446)"/>
    <defs>
        <linearGradient id="paint0_linear_1096_446" x1="12.7714" y1="27.2286" x2="27.2286" y2="27.2286" gradientUnits="userSpaceOnUse">
            <stop stop-color="#918CCE"/>
            <stop offset="0.604067" stop-color="#C5C4E6"/>
            <stop offset="0.604167" stop-color="#C5C4E6"/>
            <stop offset="1" stop-color="#F7F9FD"/>
        </linearGradient>
    </defs>
</svg>
`
