import { fullscreenIconOn, fullscreenIconOff } from './icons'

declare global {
	interface HTMLElement {
		msRequestFullscreen?: () => Promise<void>
		mozRequestFullscreen?: () => Promise<void>
		webkitRequestFullscreen?: () => Promise<void>
	}

	interface Document {
		webkitFullscreenElement: HTMLElement
		webkitExitFullscreen: () => Promise<void>
	}
}

export class FullscreenButton {
	private _controlledElement: HTMLElement
	private _button: HTMLButtonElement
	private _on: boolean = false
	private _fsFunc: () => Promise<void>
	private _disabled: boolean = false

	constructor(controlledElement: HTMLElement, button: HTMLButtonElement) {
		this._controlledElement = controlledElement
		this._button = button
		this._fsFunc = this._getFullscreenMethod()
		if (typeof this._fsFunc !== 'undefined') {
			this._button.addEventListener('click', () => {
				this._toggleFullscreen()
			})
			this._button.innerHTML = fullscreenIconOff
		} else {
			this._disabled = true
		}
	}

	private _getFullscreenMethod(): () => Promise<void> {
		const requestMethod =
			this._controlledElement.requestFullscreen ||
			this._controlledElement.webkitRequestFullscreen ||
			this._controlledElement.mozRequestFullscreen ||
			this._controlledElement.msRequestFullscreen
		return requestMethod
	}

	private _toggleFullscreen(): void {
		if (document.webkitFullscreenElement) {
			document.webkitExitFullscreen()
		} else if (document.fullscreenElement) {
			document.exitFullscreen()
		} else {
			this._fsFunc.call(this._controlledElement)
		}
		this._on = !this._on
		this.setIcon()
	}

	private setIcon(): void {
		this._button.innerHTML = this._on ? fullscreenIconOn : fullscreenIconOff
	}

	public hide(): void {
		this._button.classList.add('hidden')
	}

	public show(): void {
		if (!this._disabled) {
			this._button.classList.remove('hidden')
		}
	}
}
