import { Config, Data } from 'vizzu'

import { Component } from './component'
import { closeIcon } from '../icons'
import { SeriesType } from '../data-series'
import { DataSeriesItem } from './data-series-item'
import { DragImage, DragImageState } from '../drag-image'
import { ConfigHelper } from '../config-helper'

const cls = 'data-series-panel'

export enum ChannelState {
	ON,
	OFF
}

export class DataSeriesPanel extends Component {
	public draggedType: 'channel' | 'name' | null = null

	private closeBtn: HTMLElement
	private openBtn: HTMLElement
	private menu: HTMLElement
	private draggedSeries: DataSeriesItem | null = null
	private dragImage: DragImage
	private emptyImg: HTMLImageElement
	private hidden: boolean = true
	private queuedUpdate: number | null = null
	private lastSelection: DataSeriesItem | null = null
	private configHelper: ConfigHelper | null = null
	private series: DataSeriesItem[] = []

	constructor(parentEl: HTMLElement) {
		super(cls)
		this.el.innerHTML = this.render()
		parentEl.appendChild(this.el)
		this.closeBtn = this.el.querySelector(`.${cls}__close`)!
		this.openBtn = this.el.querySelector(`.${cls}__open`)!
		this.menu = this.el.querySelector(`.${cls}__menu`)!
		this.dragImage = new DragImage(this.el)
		this.emptyImg = document.querySelector('.empty-img')!
		this.addEventHandlers()
	}

	protected render(): string {
		return `
            <div class="${cls}__icon ${cls}__open hidden" data-tooltip="Show data series">Data</div>
            <div class="${cls}__menu ${cls}__menu--hidden">
                <div class="${cls}__icon ${cls}__close hidden" data-tooltip="Close panel">
                    ${closeIcon}
                </div>
                <div class="${cls}__content">
                    <h2 class="${cls}__subtitle">t</h2>
                    <div class="${cls}__dimensions"></div>
                    <h2 class="${cls}__subtitle">#</h2>
                    <div class="${cls}__measures"></div>
                </div>
            </div>
        `
	}

	private setChannel(series: string, type: SeriesType, state: ChannelState): void {
		this.trigger('channelupdate', {
			series,
			type,
			state
		})
	}

	private addEventHandlers(): void {
		this.closeBtn.addEventListener('pointerup', () => {
			this.close()
		})
		this.openBtn.addEventListener('pointerup', () => {
			this.open()
		})
	}

	public close(): void {
		if (this.hidden) return

		this.menu.classList.add(`${cls}__menu--hidden`)
		this.closeBtn.classList.add('hidden')
		this.openBtn.classList.remove('hidden')
	}

	public open(): void {
		this.menu.addEventListener(
			'transitionend',
			() => {
				this.trigger('panelopen')
			},
			{ once: true }
		)
		this.menu.classList.remove(`${cls}__menu--hidden`)
		this.closeBtn.classList.remove('hidden')
		this.openBtn.classList.add('hidden')
	}

	public hide(): void {
		this.hidden = true
		this.menu.classList.add(`${cls}__menu--hidden`)
		this.closeBtn.classList.add('hidden')
		this.openBtn.classList.add('hidden')
	}

	public unhide(): void {
		this.hidden = false
		this.openBtn.classList.remove('hidden')
	}

	public setContent(series: Data.SeriesInfo[], config: Config.Chart): void {
		const dimensions = series.filter((row) => row.type === 'dimension')
		const measures = series.filter((row) => row.type === 'measure')
		this.configHelper = new ConfigHelper(config)
		const dimensionsEl = this.el.querySelector(`.${cls}__dimensions`)! as HTMLElement
		const measuresEl = this.el.querySelector(`.${cls}__measures`)! as HTMLElement
		dimensionsEl.innerHTML = ''
		dimensions.forEach((item) => {
			this.addSeries(dimensionsEl, item.name, 'dimension')
		})
		measuresEl.innerHTML = ''
		measures.forEach((item) => {
			this.addSeries(measuresEl, item.name, 'measure')
		})
	}

	private addSeries(el: HTMLElement, name: string, type: SeriesType): void {
		const series = new DataSeriesItem(
			el,
			{
				name,
				type,
				isSelected: this.configHelper!.isSelected(name),
				channel: this.configHelper!.getChannelFromName(name)
			},
			this
		)
		series.on('seriesselect', () => {
			this.setChannel(series.name, series.type, ChannelState.ON)
		})
		series.on('seriesdeselect', () => {
			this.setChannel(series.name, series.type, ChannelState.OFF)
		})
		series.on('channeldragstart', (e) => {
			this.onChannelDragStart((e as CustomEvent).detail, series)
		})
		series.on('namedragstart', () => {
			this.onNameDragStart(series)
		})
		series.on('seriesdragenter', () => {
			this.onSeriesDragEnter(series)
		})
		series.on('seriesdrop', () => {
			this.onSeriesDrop(series)
		})
		series.on('seriesdragend', (e) => {
			this.onSeriesDragEnd(series, e as CustomEvent)
		})
		this.series.push(series)
	}

	private onSeriesDragEnd(series: DataSeriesItem, e: CustomEvent): void {
		const parentMenu =
			series.type === 'dimension'
				? this.el.querySelector(`.${cls}__dimensions`)!
				: this.el.querySelector(`.${cls}__measures`)!
		if (!this.isPositionOverElement(e.detail.pos, parentMenu as HTMLElement)) {
			this.trigger('seriesdragcancel')
		}
		this.resetDragStates()
	}

	private onSeriesDragEnter(series: DataSeriesItem): void {
		this.dragImage.updatePosition(series.el)
		if (this.lastSelection !== series) {
			this.queueChannelSwitch(series)
		}
	}

	private queueChannelSwitch(series: DataSeriesItem): void {
		if (this.queuedUpdate !== null) {
			window.clearTimeout(this.queuedUpdate)
		}
		this.queuedUpdate = window.setTimeout(() => {
			this.trigger('channelswitch', {
				from: this.lastSelection!.toDataSeries(),
				to: series.toDataSeries()
			})
			this.lastSelection = series
		}, 500)
	}

	private onChannelDragStart(e: Event, series: DataSeriesItem): void {
		this.trigger('channeldragstart')
		this.series
			.filter((item) => item.type !== series.type)
			.forEach((item) => {
				item.disabled = true
			})
		this.draggedSeries = series
		;(e as DragEvent).dataTransfer!.setDragImage(this.emptyImg, 0, 0)
		this.dragImage.setFromChannel(this.draggedSeries!.channelType!)
		this.dragImage.updatePosition(this.draggedSeries!.el)
		this.lastSelection = series
		this.draggedType = 'channel'
	}

	private onNameDragStart(series: DataSeriesItem): void {
		this.draggedSeries = series
		this.draggedType = 'name'
	}

	private onSeriesDrop(series: DataSeriesItem): void {
		if (this.draggedType === 'channel') {
			this.onChannelDrop(series)
		} else if (this.draggedType === 'name') {
			this.onNameDrop(series)
		}
		this.draggedType = null
	}

	private onChannelDrop(series: DataSeriesItem): void {
		this.series.forEach((item) => {
			item.disabled = false
		})
		if (this.queuedUpdate !== null) {
			window.clearTimeout(this.queuedUpdate)
			this.queuedUpdate = null
			this.trigger('channelswitch', { from: this.lastSelection, to: series })
		}
		this.trigger('channeldrop')
	}

	private onNameDrop(series: DataSeriesItem): void {
		if (this.draggedSeries!.type !== series.type) {
			this.trigger('seriesmove', { series: this.draggedSeries! })
		}
	}

	private resetDragStates(): void {
		this.series.forEach((item) => {
			item.resetDragStates()
		})
		this.dragImage.animate(DragImageState.inactive)
		this.draggedSeries = null
		this.lastSelection = null
	}
}
