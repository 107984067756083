import { csvParse } from 'd3';
export class VizzuParser {
    constructor() {
        this.columnTypes = [];
    }
    buildData(records) {
        if (records.length === 0) {
            return null;
        }
        const series = Object.entries(records[0]).map((entry, i) => ({
            name: entry[0],
            type: this.columnTypes[i]
        }));
        return {
            series,
            records: records.map(entry => Object.values(entry))
        };
    }
    getType(colData) {
        const isNumber = (x) => !Number.isNaN(+x);
        if (colData.every(x => isNumber(x))) {
            return 'measure';
        }
        else {
            return 'dimension';
        }
    }
    parse(input) {
        const parsedInput = csvParse(input);
        this.columnTypes = this.getTypes(parsedInput);
        return this.buildData(parsedInput);
    }
    getTypes(records) {
        if (records.length === 0) {
            return [];
        }
        const types = [];
        for (let i = 0; i < Object.values(records[0]).length; i++) {
            const colData = records.map(row => Object.values(row)[i]);
            types.push(this.getType(colData));
        }
        return types;
    }
}
