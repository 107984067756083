import { Position } from './position'

class Way {
	private _start: Position
	private _end: Position

	element: string

	constructor(pos: Position, element: string) {
		this._start = pos
		this._end = pos
		this.element = element
	}

	update(pos: Position): void {
		this._end = pos
	}

	difference(): Position {
		return this._end.sub(this._start)
	}
}

export interface Options {
	startThreshold: number
	acceptTreshold: number
	xInterval: number
	yInterval: number
}

export interface Events {
	started: (element: string, direction: Position) => void
	changed: (progress: number) => void
	finished: () => boolean
	canceled: () => boolean
}

export class SwipeTracker {
	private _way: Way | null = null
	private _locked: boolean = false
	private _direction: Position | null = null

	constructor(
		private _options: Options,
		private _events: Events
	) {}

	setup(options: Options): void {
		this._options = options
	}

	lock(): void {
		this._locked = true
	}

	reset(): void {
		this._way = null
		this._locked = false
		this._direction = null
	}

	mousedown(pos: Position, element: string): void {
		if (this._locked || this._way !== null) return
		this._way = new Way(pos, element)
	}

	mousemove(pos: Position): void {
		if (this._locked || this._way === null) return
		this._way.update(pos)
		const direction = this._way.difference().direction()
		if (this._direction === null) {
			if (this._distance() >= this._options.startThreshold) {
				this._direction = direction
				this._events.started(this._way.element, this._direction)
			}
		} else if (!this._direction.eq(direction)) {
			this._cancel()
		} else {
			this._events.changed(this._getProgress())
		}
	}

	mouseup(): void {
		if (this._locked || this._way === null) return
		if (this._direction !== null) {
			const progress = this._getProgress()
			if (progress >= this._options.acceptTreshold) {
				this._finish()
			} else {
				this._cancel()
			}
		} else {
			this.reset()
		}
	}

	private _cancel(): void {
		if (this._events.canceled()) {
			this.reset()
		} else {
			this.lock()
		}
	}

	private _finish(): void {
		if (this._events.finished()) {
			this.reset()
		} else {
			this.lock()
		}
	}

	private _distance(): number {
		if (!this._way) return 0

		return this._way.difference().chebyshevDistance()
	}

	private _getProgress(): number {
		if (!this._way) return 0

		const interval = this._way.difference().horizontal()
			? this._options.xInterval
			: this._options.yInterval
		return Math.min(1, (this._distance() - this._options.startThreshold) / interval)
	}
}
