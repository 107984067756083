import { Data } from 'vizzu'
import { SeriesType } from '../data-series'
import { Component } from './component'
import { sizeIcon, colorIcon, lightnessIcon } from '../icons'
import { DataSeriesPanel } from './data-series-panel'

const cls = 'data-series'

export enum ChannelType {
	noop = 'noop',
	color = 'color',
	size = 'size',
	x = 'x',
	y = 'y',
	lightness = 'lightness'
}

type DataSeriesOptions = {
	name: string
	type: SeriesType
	isSelected: boolean
	channel: ChannelType | null
}

export class DataSeriesItem extends Component {
	public name: string
	public type: SeriesType
	public channelType: ChannelType | null
	private active: boolean = false
	private _selected: boolean = false
	private animationTime: number
	private _disabled: boolean = false
	private parentEl: HTMLElement
	private parent: DataSeriesPanel
	private dragPos: { x: number; y: number } = { x: 0, y: 0 }

	constructor(
		parentEl: HTMLElement,
		{ name, type, isSelected, channel }: DataSeriesOptions,
		parent: DataSeriesPanel
	) {
		super(cls)
		this.name = name
		this.type = type
		this.selected = isSelected
		this.channelType = channel
		this.parentEl = parentEl
		this.parent = parent
		this.el.innerHTML = this.render()
		parentEl.appendChild(this.el)
		this.animationTime = this.getCSSPropertyValue('--animation-time')
		this.addEventListeners()
	}

	private addEventListeners(): void {
		this.el.addEventListener('pointerdown', (e) => {
			this.setActive(e)
		})
		this.el.addEventListener('pointerleave', () => {
			this.active = false
		})
		this.el.addEventListener('pointerup', () => {
			this.toggleState()
		})
		this.el.querySelector(`.${cls}__channel`)?.addEventListener('dragstart', (e: Event) => {
			this.startChannelDrag(e)
		})
		this.el.querySelector(`.${cls}__name`)?.addEventListener('dragstart', (e: Event) => {
			if (!this.selected) {
				this.startNameDrag(e)
			} else {
				e.preventDefault()
			}
		})
		this.el.addEventListener('dragenter', (e: Event) => {
			this.onDragEnter(e)
		})
		this.el.addEventListener('dragover', (e: Event) => {
			e.preventDefault()
		})
		this.el.addEventListener('dragleave', (e: Event) => {
			this.onDragLeave(e)
		})
		this.el.addEventListener('drop', (e: Event) => {
			this.onDrop(e)
		})
		this.el.addEventListener('dragend', (e: Event) => {
			this.trigger('seriesdragend', { pos: this.dragPos, event: e })
		})
		this.el.addEventListener('drag', (e: Event) => {
			this.dragPos = { x: (e as DragEvent).clientX, y: (e as DragEvent).clientY }
		})
	}

	private onDragEnter(e: Event): void {
		e.preventDefault()
		if (this.disabled) return

		if (this.parent.draggedType === 'channel') {
			this.addModifier(`${cls}__name`, 'dragover')
			this.trigger('seriesdragenter')
		}
	}

	private onDragLeave(e: Event): void {
		const elTo = document.elementFromPoint((e as MouseEvent).clientX, (e as MouseEvent).clientY)
		if (
			(elTo?.classList.contains(`${cls}__name`) && this.el.contains(elTo)) ||
			(elTo?.classList.contains(cls) && elTo.contains(e.target! as Node)) ||
			(!this.parentEl.contains(elTo) &&
				this.isPositionOverElement(
					{ x: (e as MouseEvent).clientX, y: (e as MouseEvent).clientY },
					document.querySelector('.data-series-panel__content')! // TODO: get from panel
				))
		) {
			return
		}
		this.removeModifier(`${cls}__name`, 'dragover')
	}

	private onDrop(e: Event): void {
		e.preventDefault()
		if (this.disabled) return

		if (this.parent.draggedType === 'channel') {
			this.removeModifier(`${cls}__name`, 'dragover')
			this.addModifier(`${cls}__name`, 'selected')
			this.deactivateChannel()
		}
		this.trigger('seriesdrop')
	}

	private toggleState(): void {
		if (!this.active) return

		this.selected = !this.selected
		const event = this.selected ? 'seriesselect' : 'seriesdeselect'
		this.trigger(event)
	}

	private setActive(e: PointerEvent): void {
		if ((e.target as HTMLElement).classList.contains(`${cls}__name`)) {
			this.active = true
		}
	}

	public resetDragStates(): void {
		this.removeModifier(`${cls}__name`, 'drag-select', 'dragover')
		this.removeModifier(`${cls}__channel`, 'dragging')
		this.setModifier(`${cls}__name`, this.selected, 'selected')
		this.disabled = false
	}

	public deactivateChannel(): void {
		const channel = this.el.querySelector(`.${cls}__channel`) as HTMLElement
		if (channel) {
			this.removeModifier(`${cls}__channel`, 'active')
			setTimeout(() => {
				this.removeModifier(`${cls}__channel`, 'dragging')
			}, this.animationTime)
		}
	}

	private startChannelDrag(e: Event): void {
		const de = e as DragEvent
		de.dataTransfer!.effectAllowed = 'none'
		de.dataTransfer!.dropEffect = 'none'
		de.dataTransfer!.setData('text/plain', JSON.stringify(this.name))
		this.removeModifier(`${cls}__name`, 'selected')
		this.addModifier(`${cls}__name`, 'drag-select')
		this.addModifier(`${cls}__channel`, 'dragging')
		this.trigger('channeldragstart', e)
	}

	private startNameDrag(e: Event): void {
		const de = e as DragEvent
		de.dataTransfer!.effectAllowed = 'none'
		de.dataTransfer!.dropEffect = 'none'
		de.dataTransfer!.setData('text/plain', JSON.stringify(this.name))
		this.trigger('namedragstart', e)
	}

	protected render(): string {
		const content = this.channel()
		return `
      <span
        data-item-type="${this.type}"
        data-series="${this.name}"
        class="data-series__name ${this.selected ? 'data-series__name--selected' : ''}"
        draggable="true">
          ${this.name}
      </span>
      ${content}
    `
	}

	private getChannelContent(): string {
		return (
			{
				[ChannelType.noop]: 'NO OP',
				[ChannelType.color]: colorIcon,
				[ChannelType.size]: sizeIcon,
				[ChannelType.x]: 'X',
				[ChannelType.y]: 'Y',
				[ChannelType.lightness]: lightnessIcon
			}[this.channelType!] ?? ''
		)
	}

	private channel(): string {
		const content = this.getChannelContent()
		if (content === '') {
			return ''
		} else {
			return `
        <span
          data-channel="${this.channelType}"
          class="${cls}__channel ${cls}__channel--${this.channelType}"
          draggable="true">
            ${content}
        </span>
      `
		}
	}

	get selected(): boolean {
		return this._selected
	}

	set selected(value: boolean) {
		this._selected = value
		this.setModifier(`${cls}__name`, value, 'selected')
	}

	get disabled(): boolean {
		return this._disabled
	}

	set disabled(value: boolean) {
		this._disabled = value
		this.setModifier(`${cls}__name`, value, 'disabled')
	}

	toDataSeries(): Data.Series {
		return {
			name: this.name,
			type: this.type,
			values: []
		}
	}
}
