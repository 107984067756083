export class FileLoader {
	public static load(file: File): Promise<string> {
		const loader = new Promise<string>((resolve, reject) => {
			const reader = new FileReader()
			reader.addEventListener('load', () => {
				const fileContent: string = reader.result as string
				if (fileContent === null) {
					reject(new Error(`Could not read ${file.name}`))
				} else {
					resolve(fileContent)
				}
			})
			reader.addEventListener('error', () => {
				reject(new Error(`Error reading ${file.name}`))
			})
			reader.readAsText(file)
		})
		return loader
	}
}
